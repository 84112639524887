import request from '@/utils/request';
export var defaultCampaignData = {
  id: 0,
  enabled: true,
  title: '',
  redeemablePoints: 0,
  description: '',
  validFrom: '',
  validTill: '',
  priority: 0,
  redeemLimit: 0,
  campaignsImage: []
};
export var getCampaigns = function getCampaigns(params) {
  return request({
    url: '/campaigns',
    method: 'get',
    params: params
  });
};
export var getCampaignById = function getCampaignById(id) {
  return request({
    url: "/campaigns/".concat(id),
    method: 'get'
  });
};
export var updateCampaign = function updateCampaign(id, data) {
  return request({
    url: "/campaigns/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteCampaign = function deleteCampaign(id) {
  return request({
    url: "/campaigns/".concat(id),
    method: 'delete'
  });
};
export var createCampaign = function createCampaign(data) {
  return request({
    url: '/campaigns/',
    method: 'post',
    data: data
  });
};
export var updateCampaignImages = function updateCampaignImages(id, data) {
  return request({
    url: "/campaigns/images/".concat(id),
    method: 'patch',
    data: data
  });
};
export var uploadCampaignImages = function uploadCampaignImages(data) {
  return request({
    url: '/campaigns/images',
    method: 'post',
    data: data
  });
};
export var deleteCampaignImages = function deleteCampaignImages(id) {
  return request({
    url: "/campaigns-images/".concat(id),
    method: 'delete'
  });
};
export var getCampaignsRedeems = function getCampaignsRedeems(params) {
  return request({
    url: '/campaigns-redeems',
    method: 'get',
    params: params
  });
};